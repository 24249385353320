<template>
  <div class="hello">
    <div style="color:#fff;font-size: 20px;font-weight: bold;margin-bottom:18%;">
      <el-steps align-center>
        <el-step title="提交订单号"></el-step>
        <el-step title="等待审核"></el-step>
        <el-step title="获得奖励"></el-step>
      </el-steps>
    </div>
    <el-input v-model="form.sn" type="text" placeholder="请输入订单编号"></el-input>
    <el-select v-model="form.shop" placeholder="请选择" style="margin-top: 10%;width: 100%">
      <el-option
        v-for="item in shopsOptions"
        :key="item.id"
        :label="item.name"
        :value="item.id">
        <span style="float: left">{{ item.name }}</span>
        <span style="float: right; color: #8492a6; font-size: 13px">
          <img :src="get_img(item.platform)" style="width: 24px;margin-top: 4px">
        </span>
      </el-option>
    </el-select>
    <div style="text-align: center">
      <el-button style="margin-top:10%;" type="primary" :loading="loadflag" @click="com_order">立即提交</el-button>
    </div>
    <img src="../assets/1.png">
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Order',
  data () {
    return {
      screeHeight: window.screen.height, // 屏幕高
      loadflag: false,
      form: {
        sn: '',
        shop: '',
        m: 0
      },
      shopsOptions: []
    }
  },
  methods: {
    com_order () {
      this.loadflag = true
      const _this = this
      axios.post('https://tg.tdfen.com/h5/order/submit', this.form).then(function (res) {
        if (res.data.code >= 0) {
          _this.$message({
            message: '提交成功，审核后奖励将直接发放到支付宝',
            type: 'success'
          })
        } else {
          _this.$message.error(res.data.message)
        }
        _this.loadflag = false
      })
    },
    get_img (platform) {
      if (platform === 1) {
        return require('../assets/tb.png')
      } else if (platform === 2) {
        return require('../assets/pdd.png')
      } else if (platform === 3) {
        return require('../assets/jd.png')
      } else if (platform === 4) {
        return require('../assets/dy.png')
      } else {
        return require('../assets/ali.png')
      }
    },
    getRequest () {
      const url = window.location.search // 获取url中"?"符后的字串
      const theRequest = {}
      if (url.indexOf('?') !== -1) {
        const str = url.substr(1)
        const strs = str.split('&')
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split('=')[0]] = decodeURI(strs[i].split('=')[1])
        }
      }
      return theRequest
    },
    get_shops () {
      const _this = this
      const url = 'https://tg.tdfen.com/h5/shop/select' + '?m=' + this.form.m
      axios.get(url).then(function (res) {
        const response = res.data
        if (response.code === 0) {
          _this.shopsOptions = response.result.data
        } else {
          console.log('false')
        }
      })
    }
  },
  mounted () {
    const params = this.getRequest()
    this.form.m = params.m
    this.form.avatar = params.avatar
    this.form.city = params.city
    this.form.province = params.province
    this.form.nickname = params.nickname
    this.form.userId = params.userId
    this.get_shops()
  }
}
</script>

<style scoped>
  .hello {
    width: 80%;
    margin: 0 auto;
    /*background: black;*/
    height: 100vh;
    padding-top: 20%;
  }
</style>
